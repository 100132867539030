import React, {Component} from 'react';
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import locales_es from "../../locales/es";
import Spinner from "../../components/spinner";
import InfiniteScroll from '../../components/InfiniteScroll/InfiniteScroll';
import MedicalRecord from "../../components/medicalRecord";
import Lottie from "react-lottie";
import animationData from "../../lotties/no-medical-records.json";
import DateTimeService from "../../modules/DateTimeService";
import FormEdit from "../formEdit";
import ConfigService from "../../modules/configService";

export default class MedicalRecords extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      medicalRecordTypes: [],
      medicalRecords: null,
      medicalRecordsNextPageUrl: null,
    };

    this.api = new APIService();
    this.helpers = new Helpers();
    this.dateTimeService = new DateTimeService();
    this.configService = new ConfigService();
  }

  componentDidMount() {
    this.getMedicalRecords();
  }

  getMedicalRecords(pageUrl = null) {
    return this.api.getMedicalRecordsByPatient(this.props.patientId, pageUrl).then(res => {
      this.setState({
        medicalRecords: this.state.medicalRecords && pageUrl ? this.state.medicalRecords.concat(res.data) : res.data,
        medicalRecordsPerPage: res.per_page,
        medicalRecordsNextPageUrl: res.next_page_url,
      });
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  onAddMedicalRecord(medicalRecord) {
    const inputs = [{
      label: locales_es.title,
      placeholder: locales_es.medicalRecordTitlePlaceholder,
      id: 1,
      state: 'title',
      preData: medicalRecord ? medicalRecord.title : '',
      type: 'text',
      required: true,
      wrapperCustomClassName: 'form-group col-12',
    }, {
      label: locales_es.description,
      placeholder: locales_es.medicalRecordDescriptionPlaceholder,
      id: 2,
      state: 'text',
      preData: medicalRecord ? medicalRecord.text : '',
      type: 'textarea',
      required: true,
      wrapperCustomClassName: 'form-group col-12',
    }, {
      label: 'Notas Privadas',
      placeholder: locales_es.medicalRecordInternalNotesPlaceholder,
      id: 3,
      state: 'internal_notes',
      preData: medicalRecord ? medicalRecord.internal_notes : '',
      type: 'textarea',
      required: false,
      wrapperCustomClassName: 'form-group col-12',
    }, {
      label: locales_es.date,
      placeholder: locales_es.date,
      id: 10,
      state: '_date',
      preData: medicalRecord ? this.dateTimeService.parseAPIStringToDate(medicalRecord.date) : '',
      type: 'date',
      required: false,
      wrapperCustomClassName: 'form-group col-12 col-md-4 float-left',
      maxDate: new window.Date().getTime(),
    }, {
      label: locales_es.weight,
      placeholder: locales_es.medicalRecordWeightPlaceholder,
      id: 4,
      state: 'weight',
      preData: medicalRecord ? medicalRecord.weight : '',
      type: 'number',
      required: false,
      step: '0.01',
      wrapperCustomClassName: 'form-group col-6 col-md-4 float-left',
    }, {
      label: locales_es.height,
      placeholder: locales_es.medicalRecordHeightPlaceholder,
      id: 5,
      state: 'height',
      preData: medicalRecord ? medicalRecord.height : '',
      type: 'number',
      required: false,
      step: '0.01',
      wrapperCustomClassName: 'form-group col-6 col-md-4 float-left',
    }];

    const customStyles = {height: 'auto', maxHeight: 640, overflow: 'auto', maxWidth: '90%'}

    const onSubmit = (inputs) => {
      // API hardcoded for now
      inputs.medical_record_type_id = 1; // TODO REMOVE?
      inputs.patient_id = this.props.patientId;
      inputs.date = this.dateTimeService.parseDateToAPIString(new Date(inputs._date));

      this.setLoading(true);
      if (medicalRecord && medicalRecord.id) {
        this.api.putMedicalRecord(medicalRecord.id, inputs).then(res => {
          this.props.showMainModal(locales_es.successModal.title, res.message);
          this.getMedicalRecords();
          this.setLoading(false);
        }).catch(err => {
          this.setLoading(false);
          alert(this.helpers.getErrorMsg(err));
        })
      } else {
        this.api.postMedicalRecord(inputs).then(res => {
          this.props.showMainModal(locales_es.successModal.title, res.message);
          this.getMedicalRecords();
          this.setLoading(false);
        }).catch(err => {
          this.setLoading(false);
          alert(this.helpers.getErrorMsg(err));
        })
      }
    }

    this.props.showMainModal(locales_es.addMedicalRecord, <FormEdit inputs={inputs}
                                                                    onSubmitButtonText={medicalRecord && medicalRecord.id ? locales_es.save : locales_es.send}
                                                                    onSubmit={onSubmit}
    />, customStyles, null);
  }

  onRemoveMedicalRecord(recordId) {
    this.props.showMainModal(locales_es.confirmActionModal.title, locales_es.confirmActionModal.subtitle, null, [{
      label: locales_es.confirmActionModal.accept, class: 'btn btn-danger', method: () => {
        this.api.deleteMedicalRecord(recordId).then(res => {
          this.props.showMainModal(locales_es.successModal.title, res.message);
          this.getMedicalRecords();
        }).catch(err => {
          this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        });
      }
    }, {
      label: locales_es.confirmActionModal.cancel, class: 'btn btn-brand',
    }]);
  }

  setLoading(bool) {
    this.setState({loading: bool});
  }

  handleCheckboxChange(event, typeId) {
    const checkbox = event.target;
    const isChecked = checkbox.checked;

    // Buscar el checkbox correspondiente en el DOM y actualizar su estado
    const pdfTypes = document.getElementById('pdf-types');
    const checkboxes = pdfTypes.querySelectorAll('input[type="checkbox"]');

    checkboxes.forEach(checkbox => {
      if (parseInt(checkbox.dataset.typeId, 10) === typeId) {
        checkbox.checked = isChecked;
      }
    });
  }

// Modifica la función `showExportPDFModal` para asignar `onChange`
  showExportPDFModal() {
    this.setLoading(true);
    this.api.getMedicalRecordTypes().then(res => {
      this.setLoading(false);
      const medicalRecordTypes = res.data.map(type => ({
        ...type,
        checked: type.id === 1 ?? false
      }));
      const content = (
        <div id="pdf-types">
          <p>{locales_es.exportPDFMedicalRecordSubtitle}</p>
          <div className="text-left">
            {medicalRecordTypes.map(type =>
              <div className="row mt-3" key={`medical-record-type-${type.id}`}>
                <div className="col">
                  <label className="kt-checkbox">
                    <input
                      type="checkbox"
                      defaultChecked={type.checked || false}
                      data-type-id={type.id} // Guardamos el ID en un atributo de datos
                      onChange={(event) => this.handleCheckboxChange(event, type.id)}
                    />
                    <span/> {type.name}
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>
      );

      this.props.showMainModal(
        locales_es.exportPDFMedicalRecord,
        content,
        {height: 400},
        [
          {
            label: locales_es.export,
            class: 'btn btn-brand',
            method: () => this.exportPDF()
          },
          {
            label: locales_es.confirmActionModal.cancel,
            class: 'btn btn-outline-brand',
          }
        ]
      );
    }).catch(err => {
      this.setLoading(false);
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }


  exportPDF() {
    this.setLoading(true);
    const pdfTypes = document.getElementById('pdf-types');
    const checkboxes = pdfTypes.querySelectorAll('input[type="checkbox"]');
    const selectedTypes = Array.from(checkboxes)
      .filter(checkbox => checkbox.checked)
      .map(checkbox => parseInt(checkbox.dataset.typeId, 10));

    this.configService.getLocalClinicData().then(clinic => {
      const objData = {
        types_ids: selectedTypes
      };

      if (clinic && clinic.id) {
        objData.clinic_id = clinic.id;
      }

      this.api.getMedicalRecordExport(this.props.patientId, objData).then(res => {
        this.setLoading(false);
        if (res.data && res.data.url) {
          window.open(res.data.url, '_blank');
        }
      }).catch(err => {
        this.setLoading(false);
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      });
    }).catch(err => {
      this.setLoading(false);
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  render() {
    const {loading, medicalRecords, medicalRecordsPerPage, medicalRecordsNextPageUrl} = this.state;

    const animationOptions = {
      loop: true, autoplay: true, animationData: animationData, rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
    const animationSize = 150;

    return (
      <>
        {loading ? <Spinner/> : null}
        <div className="kt-portlet kt-portlet--height-fluid">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">{locales_es.medicalRecord}</h3>
            </div>
            <div className="align-self-center">
              <a onClick={(ev) => {
                ev.preventDefault();
                this.showExportPDFModal();
              }} href="#" className="btn btn-label-danger btn-bold mr-2">
                <i className="flaticon2-file p-0 pr-md-2"/><span
                className="d-none d-md-inline"> {locales_es.exportPDFMedicalRecord}</span>
              </a>
              <a onClick={(ev) => {
                ev.preventDefault();
                this.onAddMedicalRecord();
              }} href="#" className="btn btn-label-brand btn-bold">
                <i className="flaticon2-add-1 p-0 pr-md-2"/><span
                className="d-none d-md-inline">{locales_es.addMedicalRecord}</span>
              </a>
            </div>
          </div>
          <div className="kt-portlet__body">
            {medicalRecords === null ? <Spinner/> : medicalRecords && medicalRecords.length ? (
              <InfiniteScroll
                dataLength={medicalRecordsPerPage}
                next={() => this.getMedicalRecords(medicalRecordsNextPageUrl)}
                hasMore={Boolean(medicalRecordsNextPageUrl)}
                loader={<Spinner/>}
                endMessage={<p className="text-center">
                  <b>{locales_es.noMoreRecords}</b>
                </p>}
              >
                {medicalRecords.map(record => (
                  <MedicalRecord
                    key={record.id}
                    record={record}
                    onEditMedicalRecord={(record) => this.onAddMedicalRecord(record)}
                    onRemoveMedicalRecord={(recordId) => this.onRemoveMedicalRecord(recordId)}
                  />
                ))}
              </InfiniteScroll>
            ) : (
              <div className="text-center">
                <Lottie
                  options={animationOptions}
                  height={animationSize}
                  width={animationSize}
                />
                <p>{locales_es.medicalRecordsNotFoundYet}</p>
                <p>
                  <a href="#" onClick={(ev) => {
                    ev.preventDefault();
                    this.onAddMedicalRecord();
                  }} className="btn btn-brand btn-upper btn-bold kt-align-center">
                    {locales_es.addMedicalRecord}
                  </a>
                </p>
              </div>
            )}
          </div>
        </div>
      </>);
  }
}
