import React, {useEffect, useState} from 'react';
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import Spinner from "../spinner";
import locales_es from "../../locales/es";
import NoAttentionPlaces from "../noAttentionPlaces";
import markerIcon from '../../images/marker-pin.png';
import {HREF_PAGE_ADD_CLINIC} from "../../models/constants";
import Loading from "../loading";

const AttentionPlaces = ({history, medic, showMainModal}) => {
  const api = new APIService();
  const helpers = new Helpers();

  const [loading, setLoading] = useState(false);
  const [places, setPlaces] = useState(null);

  const goToEditClinic = (clinicId) => {
    // window.location.href = `${HREF_PAGE_ADD_CLINIC}/${medic.id}/${clinicId}`;
    history.push(`${HREF_PAGE_ADD_CLINIC}/${medic.id}/${clinicId}?redirect=${window.location.pathname}`,)
  }

  const deleteClinic = (clinicId) => {
    showMainModal(locales_es.deleteAttentionPlace, locales_es.confirmActionModal.subtitle, null, [
      {
        class: 'btn btn-danger btn-pill m-3 align-self-start',
        label: locales_es.delete,
        method: () => confirmDeleteClinic(clinicId)
      },
      {
        class: 'btn btn-secondary btn-pill m-3 align-self-start',
        label: locales_es.cancel,
      }
    ])
  }

  const confirmDeleteClinic = (clinicId) => {
    setLoading(true);
    api.deleteClinic(clinicId).then((res) => {
      setLoading(false);
      showMainModal(locales_es.errorModal.title, res.message);
      loadClinics();
    }).catch(err => {
      setLoading(false);
      showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    })
  }

  const loadClinics = () => {
    api.getMyClinics().then(res => {
      setPlaces(res.data);
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, helpers.getErrorMsg(err));
    });
  }

  useEffect(() => {
    loadClinics();
  }, [])

  return (
    <>
      {loading && <Loading/>}
      {places === null ? <Spinner/> :
        places && places.length ?
          <>
            <div class="kt-portlet kt-portlet--height-fluid-half kt-widget-14">
              <div class="kt-portlet__body">{places.map(place =>
                <div key={place.id} class="kt-widget-14__body mb-3">
                  <div class="kt-widget-14__product">
                    <div class="kt-widget-14__thumb">
                      <a href="#" onClick={(e) => {
                        e.preventDefault();
                        goToEditClinic(place.id);
                      }}><img src={markerIcon} className="kt-widget-14__image--landscape" alt="" title=""/></a>
                    </div>
                    <div class="kt-widget-14__content">
                      <a href="#" onClick={(e) => {
                        e.preventDefault();
                        goToEditClinic(place.id);
                      }}>
                        <h3 class="kt-widget-14__title">{place.name}</h3>
                      </a>
                      {place.address &&
                        <div class="kt-widget-14__desc">
                          {place.address} {place.floor ? `- ${place.floor}` : ''}
                        </div>
                      }
                    </div>
                  </div>
                  <div class="kt-widget-14__data">
                    <button onClick={() => goToEditClinic(place.id)} type="button"
                            className="btn btn-brand btn-elevate btn-pill m-3 align-self-start">{locales_es.edit}</button>
                    <button onClick={() => deleteClinic(place.id)} type="button"
                            className="btn btn-danger btn-elevate btn-pill m-3 align-self-start">{locales_es.delete}</button>
                  </div>
                </div>
              )}
              </div>
            </div>
            <div className="text-center m-4">
              <a href={`${HREF_PAGE_ADD_CLINIC}/${medic.id}?redirect=${window.location.pathname}`}
                 className="btn btn-brand btn-sm btn-bold btn-upper">+ Agregar Lugar de Atención</a>
            </div>
          </>
          : <NoAttentionPlaces medicId={medic.id}/>
      }
    </>
  );
};

export default AttentionPlaces;
