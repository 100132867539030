import React, {Component} from "react";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import Spinner from "../../components/spinner";
import locales_es from "../../locales/es";
import AuthService from "../../modules/authService";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
  HREF_PAGE_ADD_PATIENT,
  HREF_PAGE_PATIENT,
  HREF_PAGE_PATIENTS,
  USER_TYPE_MEDIC, USER_TYPE_SECRETARY,
} from "../../models/constants";
import DateTimeService from "../../modules/DateTimeService";
import Subheader from "../../components/subheader";
import ViewHelpers from "../../modules/viewHelpers";
import {TablePaging} from "../../components/TablePaging";
import ModalInviteUser from "../../components/modalInviteUser";
import ConfigService from "../../modules/configService";
import Form from "../../components/form";
import ContactButtons from "../../components/ContactButtons";

export default class PatientsPage extends Component {
  constructor(props) {
    super(props);

    this.api = new APIService();
    this.helpers = new Helpers();
    this.auth = new AuthService();
    this.dateTimeService = new DateTimeService();
    this.viewHelpers = new ViewHelpers();
    this.perPageDefault = 5;
    this.configService = new ConfigService();

    this.state = {
      patients: null,
      identificationOptions: [],
      inviteUserId: null,
      medic_id: this.auth.getLocalUserType() === USER_TYPE_MEDIC ? this.auth.getUserData().user.id : null,
    };

  }

  componentDidMount() {
    this.api.getIdentificationTypes().then(res => {
      this.setState({
        identificationOptions: res.data
      }, () => this.auth.getLocalUserType() === USER_TYPE_SECRETARY ? this.getMedics() : this.getPatients())
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });

    if (this.helpers.isMobile()) {
      window.addEventListener('scroll', () => this.handleScroll());
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => this.handleScroll())
  }

  handleScroll() {
    if (((window.innerHeight + window.scrollY + 5) >= document.body.scrollHeight) && this.state.patients !== null) {
      // you're at the bottom of the page
      if (!this.state.refreshing && this.state.pagingData && this.state.pagingData.next_page_url) {
        this.getPatients(null, this.state.pagingData.next_page_url);
      }
    }
  }

  getMedics() {
    this.configService.getLocalClinicData().then(clinic => {
      this.setState({
        clinicId: clinic.id
      }, () => {
        if (this.state.clinicId) {
          const params = {clinic_id: this.state.clinicId};
          if (this.auth.getLocalUserType() === USER_TYPE_SECRETARY) {
            params.show_disabled = true;
          }
          this.api.getMedics(params)
            .then((res) => {
              this.setState({
                medics: [{value: 0, label: 'Todos los especialistas'}, ...res.data.map(medic => {
                  medic.value = medic.id;
                  medic.label = `${medic.lastname} ${medic.name}`;
                  return medic;
                })],
                patients: []
              }, () => {
                this.loadDefaultPatients();
              });
            }).catch(err => {
            this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
            this.setState({
              medics: [],
            });
          });
        }
      });
    }).catch(err => {
      console.log(err);
    });
  }

  loadDefaultPatients() {
    this.handleReactSelectChange('medic')(this.state.medics[0]);
  }

  getPatients(params, pageUrl) {
    window.clearPopovers();
    this.setState({
      refreshing: true
    }, () => {
      let promise;
      if (this.auth.getLocalUserType() === USER_TYPE_SECRETARY) {
        promise = this.api.getPatients;
        params = params || {};
        if (this.state.medic_id) {
          params.medic_id = this.state.medic_id;
        }
      } else {
        promise = this.api.getMyPatients;
      }
      promise(params, pageUrl).then(res => {
        const pagingData = JSON.parse(JSON.stringify(res));
        delete pagingData.data;
        const _patients = JSON.parse(JSON.stringify(this.state.patients));
        this.setState({
          patients: _patients ? _patients.concat(res.data) : res.data,
          apiPatients: _patients ? _patients.concat(res.data) : res.data,
          pagingData,
          refreshing: false,
        }, () => {
          window.initPopovers();
        });
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      });
    })
  }

  onSearchSubmit(ev) {
    window.clearPopovers();
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }
    const form = document.getElementById('searchForm');
    const query = String(form.children[0].value);

    this.setState({
      query,
      patients: null
    }, () => {
      if (!query) {
        this.setState({
          query,
          patients: this.state.apiPatients,
        })
        return;
      }

      setTimeout(() => {
        if (this.state.query.length === query.length) {
          const objData = {q: query, per_page: this.state.per_page ?? this.perPageDefault}
          if (this.auth.getLocalUserType() === USER_TYPE_SECRETARY) {
            if (this.state.medic_id) {
              objData.medic_id = this.state.medic_id;
            }
          }
          this.api.searchPatients(objData).then(res => {
            const pagingData = JSON.parse(JSON.stringify(res));
            delete pagingData.data;
            const _patients = JSON.parse(JSON.stringify(this.state.patients));
            this.setState({
              patients: _patients ? _patients.concat(res.data) : res.data,
              apiPatients: _patients ? _patients.concat(res.data) : res.data,
              pagingData,
              refreshing: false,
            }, () => {
              window.initPopovers();
            });
          }).catch(err => {
            this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
          })
        } else {
          // setSearchResults([]);
        }
      }, 500); // 800
    })
  }

  onSearchClear(ev) {
    window.clearPopovers();
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }
    const form = document.getElementById('searchForm');
    form.children[0].value = '';

    this.getPatients(); // reset
  }

  onChangeTablePaging = (params, pageUrl) => {
    window.clearPopovers();
    this.setState({
      patients: null
    }, () => {
      this.getPatients(params, pageUrl);
    })
  }

  deletePatient(patientId) {
    // @WOOPI TODO: PERMITIR QUE SE PUEDA DESACTIVAR UN PACIENTE, PARA NO TENER QUE ELIMINARLO
    this.props.showMainModal('¿Desea eliminar a este paciente?', 'Esta acción no se puede deshacer.', null, [
      {
        label: 'Sí, eliminar',
        class: 'btn btn-danger btn-elevate btn-pill btn-sm',
        method: () => {
          this.api.deletePatient(patientId).then(res => {
            this.props.showMainModal(locales_es.successModal.title, res.message);
            // this.getPatients();
            this.setState({
              patients: null
            }, () => {
              this.getPatients();
            })
          }).catch(err => {
            this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
          })
        }
      },
      {
        label: 'No, no eliminar',
        class: 'btn btn-primary btn-elevate btn-pill btn-sm'
      }
    ]);
  }

  handleReactSelectChange = state => value => {
    window.clearPopovers();
    this.setState({[state]: value, medic_id: value.id, patients: null}, () => this.getPatients());
  };

  render() {
    const {patients, refreshing, inviteUserId} = this.state;

    const inputs = [];
    if (this.state.medics) {
      inputs.push(
        {
          label: locales_es.medic,
          placeholder: locales_es.medic,
          id: 14,
          state: 'medic',
          value: this.state.medic,
          type: 'react-select',
          required: true,
          options: this.state.medics,
          wrapperCustomClassName: 'form-group col-md-6 offset-md-3 pl-md-0',
        }
      )
    }

    return (<>
      <Subheader breadcrumbs={[{
        name: locales_es.patientsCards, href: HREF_PAGE_PATIENTS
      }]}/>
      <div className="kt-container">

        {inputs && inputs.length ?
          <div className="row text-center">
            <div className="col">
              <Form
                styles="kt-form"
                inputs={inputs}
                handleReactSelectChange={this.handleReactSelectChange}
                showTerms={false}
                wrapper={true}
              />
            </div>
          </div>
          : null
        }
        <div className={`col text-center ${!patients && !this.state.medic_id ? 'text-lg-right' : ''} pb-1 pt-1 mb-3`}>
          <a
            href={`${HREF_PAGE_ADD_PATIENT}?redirect=${window.location.pathname}&medic_id=${this.state.medic_id}`}
            className="btn btn-brand btn-sm btn-bold btn-upper">{locales_es.addPatient}</a>
        </div>
        <div className="form-group row justify-content-center mt-3">
          {/*{this.auth.getLocalUserType() === USER_TYPE_SECRETARY && !this.state.medic_id ?*/}
          {this.state.patients?.length || this.state.apiPatients?.length || this.state.query ?
            <div className="col-12 col-lg-6 pb-1 pt-1">
              <form onSubmit={(e) => this.onSearchSubmit(e)}
                    className="kt-input-icon kt-input-icon--right" id="searchForm">
                <input className="form-control"
                       type="search"
                       onChange={(e) => this.onSearchSubmit(e)}
                       placeholder={this.helpers.isMobile() ? locales_es.searchPatients : locales_es.searchByPatientsNameOrLastname}/>
                {this.state.query ?
                  <span onClick={(e) => {
                    this.onSearchClear(e);
                    this.onSearchSubmit(e)
                  }} className="kt-input-icon__icon kt-input-icon__icon--right">
                      <span><i className="la la-close"/></span>
                  </span>
                  :
                  <span onClick={(e) => this.onSearchSubmit(e)}
                        className="kt-input-icon__icon kt-input-icon__icon--right">
                  <span><i className="la la-search"/></span>
                </span>
                }
              </form>
            </div>
            :
            null
          }
        </div>

        {patients === null ? <div className="row">
          <div className="col text-center">
            <Spinner/>
          </div>
        </div> : patients && patients.length ?
          this.helpers.isMobile() ?
            <>
              <div className="row">
                {patients.map((patient, index) => {
                  return (

                    <div key={`pcard-${index}`} className="col-xl-4 col-lg-6">

                      <div className="kt-portlet kt-portlet--height-fluid">
                        <div className="kt-widget kt-widget--general-2">
                          <div className="kt-portlet__body kt-portlet__body--fit">
                            <div className="kt-widget__top">
                              <div className="kt-media kt-media--lg kt-media--circle">
                                <img src={patient.full_profile_image} alt=""/>
                              </div>
                              <div className="kt-widget__wrapper">
                                <div className="kt-widget__label">
                                  <span className="kt-widget__title">
                                    {patient.name} {patient.lastname}
                                  </span>
                                  <span className="kt-widget__desc">{locales_es.patient}</span>
                                </div>
                              </div>
                            </div>
                            <div className="kt-widget__bottom d-block">
                              <div
                                className={`kt-widget__progress mb-3 ${this.helpers.userHasAccount(patient.user) ? '' : 'w-100'}`}>
                                <div className="kt-widget__stat">
                                      <span
                                        className="kt-widget__caption">{locales_es.email_address}: </span>
                                  <span
                                    data-toggle="popover"
                                    data-content={patient.user && this.helpers.userHasAccount(patient.user) ? patient.user.email : locales_es.hasNot}
                                    className="kt-widget__value woopi-email-user-account-list">
                                    {this.helpers.userHasAccount(patient.user)
                                      ? patient.user.email
                                      : <button onClick={() => this.setState({inviteUserId: patient.id})} type="button"
                                                className="btn btn-sm btn-outline-brand btn-elevate btn-pill m-1"
                                                style={this.helpers.userHasAccount(patient.user) ? {
                                                  position: 'absolute',
                                                  right: 20
                                                } : {}}>
                                        <i
                                          className="flaticon-user-add p-0 pr-md-1"/><span
                                        className="d-none d-md-inline"> {locales_es.hasNotAccount}. {locales_es.inviteUser}</span>
                                      </button>
                                    }</span>
                                </div>
                              </div>
                              <div className="kt-widget__progress mb-3">
                                <div className="kt-widget__stat">
                                      <span
                                        className="kt-widget__caption">{this.viewHelpers.getSelectedIdentificationTypeName(this.state.identificationOptions, patient.identification_type_id)}: </span>
                                  <span
                                    className="kt-widget__value">{patient.identification}</span>
                                </div>
                              </div>
                              {this.viewHelpers.userDataHasData(patient, 'cellphone') ?
                                <div className="kt-widget__progress mb-3">
                                  <div className="kt-widget__stat">
                                      <span
                                        className="kt-widget__caption">{locales_es.phoneNumber}: </span>
                                    <span
                                      className="kt-widget__value">{this.viewHelpers.userDataHasData(patient, 'cellphone') ?? '-'} </span>
                                    <div className="mt2">
                                      <ContactButtons patient={patient} showText={false}/>
                                    </div>
                                  </div>
                                </div> : null}
                              <div className="kt-widget__progress mb-3">
                                <div className="kt-widget__stat">
                                      <span
                                        className="kt-widget__caption">{locales_es.date_of_birth}: </span>
                                  {patient.date_of_birth && <span className="kt-widget__value"
                                                                  dangerouslySetInnerHTML={{
                                                                    __html: this.dateTimeService.parseEventDate(this.dateTimeService.parseAPIStringToDate(patient.date_of_birth), true, 'day') + ' ' + this.dateTimeService.parseEventDate(this.dateTimeService.parseAPIStringToDate(patient.date_of_birth), true, 'month') + ' ' + this.dateTimeService.parseEventDate(this.dateTimeService.parseAPIStringToDate(patient.date_of_birth), true, 'year')
                                                                  }}/>}
                                </div>
                              </div>
                              {this.auth.getLocalUserType() === USER_TYPE_SECRETARY && patient.medic ?
                                <div className="kt-widget__actions mb-3">
                                  <div className="kt-widget__stat">
                                    <span className="kt-widget__caption">{locales_es.attendedBy}: </span>
                                    <span
                                      className="kt-widget__value">{`${patient.medic.name} ${patient.medic.lastname}`}</span>
                                  </div>
                                </div>
                                : null}
                              <br/>
                              <div className="kt-widget__actions clear">
                                {(this.auth.getLocalUserType() === USER_TYPE_MEDIC) &&
                                  <a href={`${HREF_PAGE_PATIENT}/${patient.id}`}
                                     className="btn btn-success btn-sm btn-bold btn-upper">{locales_es.seeProfile}</a>
                                }
                                {this.auth.getLocalUserType() === USER_TYPE_SECRETARY &&
                                  <a href={`${HREF_PAGE_ADD_PATIENT}?patientIdToClone=${patient.id}`} type="button"
                                     className="btn btn-sm btn-focus btn-elevate btn-pill btn-outline m-1">
                                    <i className="flaticon2-copy p-0 p-lg-1"/> {locales_es.copyToOtherMedic}
                                  </a>
                                }
                                {this.auth.getLocalUserType() === USER_TYPE_SECRETARY &&
                                  <a href={`${HREF_PAGE_ADD_PATIENT}/?patientId=${patient.id}`}
                                     className="btn btn-sm btn-focus btn-elevate btn-pill btn-success m-1"><i
                                    className="flaticon2-user-1 p-0 p-lg-1"/> <span
                                    className="d-inline-block">{locales_es.seeProfile}</span></a>
                                }
                                {(this.auth.getLocalUserType() === USER_TYPE_MEDIC || this.auth.getLocalUserType() === USER_TYPE_SECRETARY) &&
                                  <button onClick={() => this.deletePatient(patient.id)} type="button"
                                          className="btn btn-danger btn-sm btn-bold btn-upper btn-pill m-1">
                                    <i className="flaticon-delete p-0 p-lg-1"/> <span
                                    className="d-none d-lg-inline-block">{locales_es.delete}</span>
                                  </button>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>)
                })}
                {refreshing && <Spinner/>}
                {this.state.pagingData
                  && (this.state.pagingData.next_page_url === null || this.state.pagingData.next_page_url === '') && <>
                    <p className="p-1 mt-2 text-center w-100">{locales_es.noMorePatients}</p>
                    <div className="col col-lg-3 text-center text-lg-right pb-1 pt-1 mt-2">
                      <a
                        href={`${HREF_PAGE_ADD_PATIENT}?redirect=${window.location.pathname}&medic_id=${this.auth.getUserData().user.id}`}
                        className="btn btn-brand btn-sm btn-bold btn-upper">{locales_es.addPatient}</a>
                    </div>
                  </>}
              </div>
            </>
            :
            <>
              <div className="row">
                <div className="col">
                  <div className="kt-portlet kt-portlet--height-fluid overflow-auto">
                    <table className="woopi-table">
                      <thead>
                      <tr className="text-center">
                        <th>
                          <span>{locales_es.nameAndLastname}</span>
                        </th>
                        <th>
                          <span>{locales_es.identification}</span>
                        </th>
                        <th>
                          <span>{locales_es.date_of_birth}</span></th>
                        <th>
                          <span>{locales_es.telephone_number}</span></th>
                        <th>
                          <span>{locales_es.email_address}</span></th>
                        {this.auth.getLocalUserType() === USER_TYPE_SECRETARY ?
                          <th>
                            <span>{locales_es.attendedBy}</span>
                          </th>
                          : null}
                        <th>
                          <span>{locales_es.actions}</span>
                        </th>
                      </tr>
                      </thead>
                      <tbody className="kt-datatable__body ps ps--active-y">
                      {patients.map((patient, index) => {
                        return (
                          <tr key={`p-${index}`} className="">
                            <td>
                                <span>
                                  <div className="kt-user-card-v2">
                                    <div className="kt-user-card-v2__pic">
                                      <img src={patient.full_profile_image} alt={locales_es.profileImage}/>
                                    </div>
                                    <div className="kt-user-card-v2__details">
                                      <span className="kt-user-card-v2__name">{patient.lastname}, {patient.name}</span>
                                      <span className="kt-user-card-v2__email">{locales_es.patient}</span>
                                    </div>
                                  </div>
                                </span>
                            </td>
                            <td>
                                <span
                                  className="kt-font-bold">{this.viewHelpers.getSelectedIdentificationTypeName(this.state.identificationOptions, patient.identification_type_id, true)} {patient.identification || locales_es.hasNot}</span>
                            </td>
                            <td>
                              {patient.date_of_birth ?
                                <span className="kt-font-bold kt-widget__value"
                                      dangerouslySetInnerHTML={{
                                        __html: this.dateTimeService.parseEventDate(this.dateTimeService.parseAPIStringToDate(patient.date_of_birth), true, 'day') + ' ' + this.dateTimeService.parseEventDate(this.dateTimeService.parseAPIStringToDate(patient.date_of_birth), true, 'month') + ' ' + this.dateTimeService.parseEventDate(this.dateTimeService.parseAPIStringToDate(patient.date_of_birth), true, 'year')
                                      }}/>
                                : locales_es.notDefined}
                            </td>
                            <td>
                                <span
                                  className="kt-font-bold">{patient.cellphone ? patient.cellphone : (patient.user?.cellphone ?? '-')}</span>
                            </td>
                            <td>
                                <span
                                  data-toggle="popover"
                                  data-content={patient.user && this.helpers.userHasAccount(patient.user) ? patient.user.email : locales_es.hasNot}
                                  className="kt-font-bold woopi-email-user-account-list">{patient.user && this.helpers.userHasAccount(patient.user) ? patient.user.email : locales_es.hasNot}</span>
                            </td>
                            {this.auth.getLocalUserType() === USER_TYPE_SECRETARY && patient.medic ?
                              <td>
                                  <span
                                    className="kt-font-bold">{`${patient.medic.name} ${patient.medic.lastname}`}</span>
                              </td>
                              : null}
                            <div className="p-3">
                              <div className="kt-widget__actions clear">
                                {(this.auth.getLocalUserType() === USER_TYPE_MEDIC || this.auth.getLocalUserType() === USER_TYPE_SECRETARY) &&
                                  <a href={`${HREF_PAGE_PATIENT}/${patient.id}`}
                                     className="btn btn-sm btn-focus btn-elevate btn-pill btn-success m-1"><i
                                    className="flaticon2-user-1 p-0 p-lg-1"/> <span
                                    className="d-none d-lg-inline-block">{locales_es.seeProfile}</span></a>
                                }
                                {(this.auth.getLocalUserType() === USER_TYPE_MEDIC || this.auth.getLocalUserType() === USER_TYPE_SECRETARY) ?
                                  this.helpers.userHasAccount(patient.user) ?
                                    null
                                    /*<button
                                      disabled={!(this.helpers.userHasAccount(patient.user))}
                                      onClick={() => window.location.href = `${HREF_PAGE_ONLINE}/${this.auth.getUserData().user.id}/${patient.user?.id}`}
                                      className="btn btn-sm btn-focus btn-elevate btn-pill btn-primary m-1"><i
                                      className="flaticon2-email p-0 p-lg-1"/> <span
                                      className="d-none d-lg-inline-block">{locales_es.sendMessage}</span></button>*/
                                    :
                                    <button onClick={() => this.setState({inviteUserId: patient.id})} type="button"
                                            className="btn btn-sm btn-outline-brand btn-elevate btn-pill m-1">
                                      <i
                                        className="flaticon-user-add"/> {locales_es.hasNotAccount}. {locales_es.inviteUser}
                                    </button>
                                  : null}
                                {this.auth.getLocalUserType() === USER_TYPE_SECRETARY &&
                                  <a href={`${HREF_PAGE_ADD_PATIENT}?patientIdToClone=${patient.id}`} type="button"
                                     className="btn btn-sm btn-focus btn-elevate btn-pill btn-outline m-1">
                                    <i className="flaticon2-copy p-0 p-lg-1"/> {locales_es.copyToOtherMedic}
                                  </a>
                                }
                                {(this.auth.getLocalUserType() === USER_TYPE_MEDIC || this.auth.getLocalUserType() === USER_TYPE_SECRETARY) &&
                                  <button onClick={() => this.deletePatient(patient.id)} type="button"
                                          className="btn btn-sm btn-focus btn-elevate btn-pill btn-danger m-1">
                                    <i className="flaticon-delete p-0 p-lg-1"/> {locales_es.removePatient}
                                  </button>
                                }
                              </div>
                            </div>
                          </tr>)
                      })}
                      </tbody>
                    </table>
                    {this.state.pagingData && <TablePaging pagingData={this.state.pagingData}
                                                           query={this.state.query}
                                                           onChange={(params, pageUrl) => this.onChangeTablePaging(params, pageUrl)}/>}
                  </div>
                </div>

              </div>
            </>

          : <>
            <div className="row">
              <div className="offset-md-3 col-md-6 text-center">
                <div className="alert alert-dark" role="alert">
                  <div className="alert-text">{locales_es.noPatientsFound}</div>
                </div>
              </div>
            </div>
          </>}

        {inviteUserId && <ModalInviteUser showMainModal={this.props.showMainModal}
                                          patientId={inviteUserId}
                                          onSubmit={() => {
                                            this.getPatients();
                                            this.setState({inviteUserId: null});
                                          }}
                                          onCancel={() => this.setState({inviteUserId: null})}/>}
        <table id="table-to-xls" className="d-none">
          <tr>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>E-Mail</th>
            <th>N° de Documento</th>
            <th>Teléfono</th>
            <th>Fecha de Nacimiento</th>
            <th>Médico</th>
          </tr>
          {patients === null ? <div className="row">
            <div className="col text-center">
              <Spinner/>
            </div>
          </div> : patients && patients.length ? patients.map((patient, index) => {
            return (<tr key={`pxls-${index}`}>
              <td>
                {patient.name}
              </td>
              <td>
                {patient.lastname}
              </td>
              <td>
                {patient.user && this.helpers.userHasAccount(patient.user) ? patient.user.email : locales_es.hasNot}
              </td>
              <td>
                {patient.identification}
              </td>
              <td>
                {patient.cellphone ? patient.cellphone : (patient.user?.cellphone ?? '-')}
              </td>
              <td>
                {patient.date_of_birth && <span className="kt-widget__value"
                                                dangerouslySetInnerHTML={{
                                                  __html: this.dateTimeService.parseEventDate(this.dateTimeService.parseAPIStringToDate(patient.date_of_birth), true, 'day') + ' ' + this.dateTimeService.parseEventDate(this.dateTimeService.parseAPIStringToDate(patient.date_of_birth), true, 'month') + ' ' + this.dateTimeService.parseEventDate(this.dateTimeService.parseAPIStringToDate(patient.date_of_birth), true, 'year')
                                                }}/>}
              </td>
              <td>
                {patient.medic ? `${patient.medic.name} ${patient.medic.lastname}` : '-'}
              </td>
            </tr>)
          }) : <tr>
            <td>
              <div
                className="kt-datatable--error">{locales_es.noPatientsFound}</div>
            </td>
          </tr>}
        </table>
        {this.state.query || this.state.patients === null || (this.state.patients && this.state.patients.length === 0) ? null :
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="btn btn-focus d-none d-lg-inline-block"
            table="table-to-xls"
            filename="Mis-Pacientes"
            sheet="tablexls"
            buttonText={locales_es.exportXLS}/>}
      </div>
    </>)
  }
}
