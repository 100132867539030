import React, {Component} from "react";
import locales_es from "../../locales/es";
import AuthService from "../../modules/authService";
import DateTimeService from "../../modules/DateTimeService";
import ViewHelpers from "../../modules/viewHelpers";

export default class MedicalRecord extends Component {
  constructor(props) {
    super(props);

    this.auth = new AuthService();
    this.dateTimeService = new DateTimeService();
    this.viewHelpers = new ViewHelpers();
  }

  getStyle(recordTypeId) {
    switch (recordTypeId) {
      case 1:
        return {
          className: "success",
          icon: "flaticon2-medical-records-1",
        };
      case 2:
        return {
          className: "warning",
          icon: "flaticon2-contract",
        };
      case 3:
        return {
          className: "primary",
          icon: "flaticon2-document",
        };
      case 4:
        return {
          className: "danger",
          icon: "flaticon2-layers-1",
        };
      default:
        return {
          className: "success",
          icon: "flaticon2-medical-records-1",
        };
    }
  }

  viewRecord(record) {
    if (record && record.medical_prescription) {
      window.open(record.medical_prescription.full_file_url, '_blank');
    }
    if (record && record.medical_certificate) {
      window.open(record.medical_certificate.full_file_url, '_blank');
    }
    if (record && record.complementary_study) {
      window.open(record.complementary_study.full_file, '_blank');
    }
  }

  render() {
    const {record, onEditMedicalRecord, onRemoveMedicalRecord} = this.props;
    return (
      <>
        <div onClick={() => this.viewRecord(record)} key={`record-${record.id}`} className={`kt-timeline ${record.medical_prescription || record.medical_certificate || record.complementary_study ?  'cursor-pointer' : ''}`}>
          <div
            className={`kt-timeline__item kt-timeline__item--${this.getStyle(record.medical_records_type_id).className}`}>
            <div className="kt-timeline__item-section">
              <div className="kt-timeline__item-section-border">
                <div className="kt-timeline__item-section-icon">
                  <i
                    className={`${this.getStyle(record.medical_records_type_id).icon} kt-font-${this.getStyle(record.medical_records_type_id).className}`}></i>
                </div>
              </div>
              {record.medical_records_type ? <span
                className={`badge badge-${this.getStyle(record.medical_records_type_id).className} ml-3`}>{record.medical_records_type.name}</span> : null}
              <div className="kt-timeline__item-datetime" dangerouslySetInnerHTML={{
                __html: this.dateTimeService.parseEventDate(this.dateTimeService.parseAPIStringToDate(record.date), true, 'day') + ' ' + this.dateTimeService.parseEventDate(this.dateTimeService.parseAPIStringToDate(record.date), false, 'month') + ' ' + this.dateTimeService.parseEventDate(this.dateTimeService.parseAPIStringToDate(record.date), true, 'year')
              }}/>
              {record.medical_records_type?.medic_can_delete && this.dateTimeService.diffTwoDates(new Date(record.created_at), new Date()).isOneHour ?
                    <a onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        onRemoveMedicalRecord && onRemoveMedicalRecord(record.id);
                    }} href="#" className="btn btn-icon kt-font-danger">
                        <i className="flaticon2-trash"></i>
                    </a>
                : null}
              {record.medical_records_type?.medic_can_edit && this.dateTimeService.diffTwoDates(new Date(record.created_at), new Date()).isOneHour ?
                    <a onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        onEditMedicalRecord && onEditMedicalRecord(record);
                    }} href="#" className="btn btn-icon kt-font-warning">
                        <i className="flaticon2-pen"></i>
                    </a>
                : null}
            </div>
              <div className="kt-timeline__item-text">
                  <h5>{record.title}</h5>
                  <div dangerouslySetInnerHTML={{__html: this.viewHelpers.formatText(record.text)}} />
                  {record.weight && <div>{locales_es.weight}: {record.weight}</div>}
                  {record.height && <div>{locales_es.height}: {record.height}</div>}
            </div>
            <div className="kt-timeline__item-info">
              {record.internal_notes}
            </div>
          </div>
        </div>

      </>
    )
  }
}
